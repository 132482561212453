export const monuments = [
    {
        id: "padrao-dos-descobrimentos",
        name: "Padrão dos Descobrimentos",
        description: "A monument celebrating the Portuguese Age of Discovery. It reflects the ambitions of the Portuguese explorers during this time.",
        smallDescription: "Celebrates the Portuguese Age of Discovery.",
        date: "1960",
        century: "Século 20",
        architectureStyle: "Moderno",
        coordinates: [-9.2155, 38.6951],
        region: "Lisboa",
        district: "Lisboa",
        municip: "Lisboa",
        local: "Belém",
        open: "09:00",
        close: "17:00",
        openDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        classification: ["Património Nacional"],
        monumentType: "Monumento Cultural",
        museum: false,
        monument: true,
        nationalMonument: true,
        municipalMonument: false,
        n2: false,
        type: "Cultural",
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "10 €",
        site: "https://padraodosdescobrimentos.pt/padrao-dos-descobrimentos/",
        additionalInfo: {
            accessibility: "Wheelchair accessible",
            historicalSignificance: "Key symbol of Portugal's maritime exploration era.",
            notableFeatures: "Sculptures of important explorers."
        },
        transportInfo: "Nearest transport: Bus 728, Tram 15E",
        events: ["Exhibition on maritime history"],
        images: [
            "https://www.example.com/image1.jpg",
            "https://www.example.com/image2.jpg"
        ]
    },
    {
        id: "belem-tower",
        name: "Torre de Belém",
        description: "A historic tower guarding the entrance to Lisbon's harbor, constructed in the early 16th century. It exemplifies the Portuguese Manueline style.",
        smallDescription: "Historic defensive tower at Lisbon's harbor.",
        date: "1519",
        century: "Século 16",
        architectureStyle: "Manuelino",
        coordinates: [-9.2159, 38.6916],
        region: "Lisboa",
        district: "Lisboa",
        municip: "Lisboa",
        local: "Belém",
        open: "10:00",
        close: "18:00",
        openDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        classification: ["Património Mundial da UNESCO", "Património Nacional"],
        monumentType: "Fortaleza",
        museum: true,
        monument: true,
        nationalMonument: true,
        municipalMonument: false,
        n2: false,
        type: "Cultural",
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "6 €",
        site: "https://torrebelem.com/pt/",
        additionalInfo: {
            bestVisitTime: "Early morning or late afternoon to avoid crowds",
            historicalSignificance: "Played a defensive role during Portugal’s maritime expansion.",
            notableFeatures: "Intricate Manueline carvings."
        },
        transportInfo: "Nearest transport: Tram 15E",
        events: ["Guided tours", "Cultural exhibits"],
        images: [
            "https://www.example.com/image3.jpg",
            "https://www.example.com/image4.jpg"
        ]
    },
    {
        id: "sao-jorge-castle",
        name: "Castelo de São Jorge",
        description: "A Moorish castle overlooking the historic center of Lisbon, offering panoramic views of the city. One of Lisbon's main tourist attractions.",
        smallDescription: "Moorish castle on a hilltop with panoramic views.",
        date: "Século 11",
        century: "Século 11",
        architectureStyle: "Mouro",
        coordinates: [-9.1335, 38.7139],
        region: "Lisboa",
        district: "Lisboa",
        municip: "Lisboa",
        local: "São Jorge",
        open: "09:00",
        close: "21:00",
        openDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        classification: ["Património Nacional"],
        monumentType: "Castelo",
        museum: false,
        monument: true,
        nationalMonument: true,
        municipalMonument: false,
        n2: false,
        type: "Cultural",
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "8.50 €",
        site: "https://castelodesaojorge.pt/",
        additionalInfo: {
            notableEvents: "Summer concerts and historical reenactments",
            historicalSignificance: "Important defensive stronghold during the Moorish period."
        },
        transportInfo: "Nearest transport: Tram 12",
        events: ["Summer concerts", "Medieval reenactments"],
        images: [
            "https://www.example.com/image5.jpg",
            "https://www.example.com/image6.jpg"
        ]
    },
    {
        id: "biblioteca-joanina",
        name: "Biblioteca Joanina",
        description: "A stunning Baroque library located in the University of Coimbra. Known for its rich history and ornate interior, it houses valuable manuscripts.",
        smallDescription: "Ornate baroque library at the University of Coimbra.",
        date: "1728",
        century: "Século 18",
        architectureStyle: "Barroco",
        coordinates: [-8.4280, 40.2078],
        region: "Coimbra",
        district: "Coimbra",
        municip: "Coimbra",
        local: "Universidade de Coimbra",
        open: "09:00",
        close: "17:00",
        openDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        classification: ["Património Nacional"],
        monumentType: "Biblioteca",
        museum: true,
        monument: true,
        nationalMonument: true,
        municipalMonument: false,
        n2: true,
        type: "Cultural",
        linkToGoogleStreet: "https://maps.google.com/examplelink",
        ticketPrice: "5 €",
        site: "https://visit.uc.pt/pt/",
        additionalInfo: {
            accessibility: "Limited access",
            notableEvents: "Guided tours available",
            historicalSignificance: "Houses rare and valuable manuscripts from Portugal's golden age."
        },
        transportInfo: "Nearest transport: Bus 103",
        events: ["Guided tours"],
        images: [
            "https://www.example.com/image7.jpg",
            "https://www.example.com/image8.jpg"
        ]
    }
];
