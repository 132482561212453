import React, { useState } from 'react';
import IconFilter from './iconFilter/IconFilter';
import FiltersReset from './filtersReset/FiltersReset';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importar ícones de menu e fechar
import './UnderHeader.css';

const UnderHeader = ({ filters, onFilterChange, onReset, isMobile }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Controle do estado do menu

  // Função para alternar a abertura/fechamento do menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="underHeaderContainer">
      {/* Ícone flutuante para abrir/fechar menu apenas no mobile */}
      {isMobile && (
        <div className="floating-icon-menu">
          <button
            className={`floating-button ${isMenuOpen ? 'open' : ''}`}
            onClick={toggleMenu} // Alternar o menu ao clicar
          >
            {isMenuOpen ? <FaTimes size={20} /> : <FaBars size={20} />} {/* Ícone de abrir/fechar */}
          </button>
          {isMenuOpen && (
            <div className="icon-filter-wrapper-mobile">
              <IconFilter filters={filters} onFilterChange={onFilterChange} />
            </div>
          )}
        </div>
      )}

      {/* Menu de ícones visível sempre no desktop */}
      {!isMobile && (
        <div className="iconFilterContainer">
          <IconFilter filters={filters} onFilterChange={onFilterChange} />
        </div>
      )}

      {/* Botão de reset visível apenas no desktop */}
      {!isMobile && (
        <div className="filtersResetContainer">
          <FiltersReset onReset={onReset} />
        </div>
      )}
    </div>
  );
};

export default UnderHeader;
