// Import necessary libraries and components
import React, { useState, useMemo, useEffect, Suspense } from "react";
import { Button } from "react-bootstrap";
import Header from "./component/header/Header";
import UnderHeader from "./component/underHeader/UnderHeader";
import MonumentList from "./component/monumentList/MonumentList";
import { monuments } from "./data/monuments";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./App.css";

// Dynamically import the MapPart component (Lazy loading)
const MapPart = React.lazy(() => import("./component/mapPart/MapPart"));

// Initial filter state for filtering monuments
const initialFilters = {
  district: "",
  architectureStyle: "",
  century: "",
  open: "",
  ticketPrice: "",
  accessibility: "",
  monument: false,
  museum: false,
};

function App() {
  // State variables for handling filters, display modes, selections, and mobile view detection
  const [filters, setFilters] = useState(initialFilters);
  const [activeFilter, setActiveFilter] = useState(false);
  const [selectedMonuments, setSelectedMonuments] = useState([]);
  const [displayMode, setDisplayMode] = useState("cards");
  const [isListVisible, setIsListVisible] = useState(true);
  const [isUnderHeaderVisible, setIsUnderHeaderVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Memoized filteredMonuments list to avoid unnecessary recalculations
  const filteredMonuments = useMemo(() => {
    // Return all monuments if no active filters
    if (!activeFilter || Object.keys(filters).every((key) => !filters[key])) {
      return monuments;
    }
    // Apply filter logic to monuments based on active filters
    return monuments.filter((monument) =>
      Object.entries(filters).every(([key, value]) => {
        if (value === "" || value === false) return true; // Skip if no filter is applied
        if (typeof value === "boolean") {
          return monument[key] === value; // Match boolean filters (monument, museum)
        }
        // Match string-based filters (district, style, etc.)
        return String(monument[key])
          .toLowerCase()
          .includes(value.toLowerCase());
      })
    );
  }, [filters, activeFilter]);

  // Function to handle filter changes and activate filters
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setActiveFilter(Object.values(newFilters).some((value) => value !== ""));
  };

  // Function to reset filters to initial state
  const handleResetFilters = () => {
    setFilters(initialFilters);
    setActiveFilter(false);
  };

  // Handle selection of a monument, toggle between selecting and deselecting
  const handleSelectMonument = (monument) => {
    setSelectedMonuments((prevSelected) =>
      prevSelected.includes(monument)
        ? prevSelected.filter((m) => m !== monument)
        : [...prevSelected, monument]
    );
  };

  // Handle singling out a specific monument (used in search or map)
  const handleSingleOutMonument = (monument) => {
    setSelectedMonuments([monument]);
  };

  // Placeholder for creating map with selected monuments
  const handleCreateMap = () => {
    console.log("Creating map with selected monuments:", selectedMonuments);
  };

  // Handle monument selection from search functionality
  const handleMonumentSelectFromSearch = (monument) => {
    handleSingleOutMonument(monument);
  };

  // Function to change the display mode between "cards" and "list"
  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
  };

  // Effect to handle responsive behavior (detect mobile view on window resize)
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Toggle the visibility of the monument list in mobile view
  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  // Render the App component's layout and main functionality
  return (
    <div className="app-container">
      {/* Header component handles filtering and search for monuments */}
      <Header
        onFilterChange={handleFilterChange}
        filters={filters}
        monuments={monuments}
        onMonumentSelect={handleMonumentSelectFromSearch}
        isMobile={isMobile}
        isUnderHeaderVisible={isUnderHeaderVisible}
      />

      {/* UnderHeader component for additional filtering options */}
      <UnderHeader
        filters={filters}
        onFilterChange={handleFilterChange}
        onReset={handleResetFilters}
        isMobile={isMobile}
      />

      <div className="content-row">
        <div className="map-containerDiv">
          {/* Lazy loaded MapPart component, which displays the selected monuments */}
          <Suspense fallback={<div>Loading Map...</div>}>
            <MapPart
              monuments={
                selectedMonuments.length > 0
                  ? selectedMonuments
                  : filteredMonuments
              }
            />
          </Suspense>
        </div>

        {/* Mobile view: show monument list with toggle button */}
        {isMobile && (
          <>
            <div
              className={`monument-list-container-mobile ${
                isListVisible ? "visible" : "hidden"
              }`}
            >
              <MonumentList
                monuments={filteredMonuments}
                displayMode={displayMode}
                onModeChange={handleDisplayModeChange}
                activeFilter={activeFilter}
                onSelectMonument={handleSelectMonument}
                onSingleOutMonument={handleSingleOutMonument}
                selectedMonuments={selectedMonuments}
                onCreateMap={handleCreateMap}
              />
            </div>

            {/* Button to toggle the visibility of the monument list */}
            <Button
              className="toggle-list-button"
              onClick={toggleListVisibility}
            >
              {isListVisible ? <FaArrowRight size={20} /> : <FaArrowLeft size={20} />}
            </Button>
          </>
        )}

        {/* Desktop view: always display monument list */}
        {!isMobile && (
          <div className="monument-list-container">
            <MonumentList
              monuments={filteredMonuments}
              displayMode={displayMode}
              onModeChange={handleDisplayModeChange}
              activeFilter={activeFilter}
              onSelectMonument={handleSelectMonument}
              onSingleOutMonument={handleSingleOutMonument}
              selectedMonuments={selectedMonuments}
              onCreateMap={handleCreateMap}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
