import React from 'react';
import FilterPart from './filterPart/FilterPart';
import SearchBar from './searchBar/SearchBar';
import './Header.css';
import logoHeader from "../../images/logoHeader.PNG";
import { FaFilter } from 'react-icons/fa';

// Header component without filter chips
const Header = ({ onFilterChange, filters, monuments, onMonumentSelect, isMobile, isUnderHeaderVisible, toggleUnderHeader }) => {

  return (
    <div className="headerContainer">
      {/* Logo pequeno à esquerda */}
      <img className="headerIcon" src={logoHeader} alt="Site Logo" />

      {/* Filtros no meio */}
      <div className="filterContainer">
        <FilterPart onFilterChange={onFilterChange} filters={filters} monuments={monuments} />
      </div>

      {/* Barra de pesquisa à direita */}
      <div className="searchContainer">
        <SearchBar monuments={monuments} onMonumentSelect={onMonumentSelect} />

       
      </div>
    </div>
  );
};

export default Header;
