import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import "./FilterPart.css";

const FilterSelect = ({ label, name, options, selected, onChange }) => (
  <Form.Control
    as="select"
    name={name}
    value={selected || ""}
    onChange={onChange}
    aria-label={`Filter by ${label}`}
  >
    <option value="">{label}</option>
    {options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </Form.Control>
);

const FilterPart = ({ monuments, filters, onFilterChange }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFilters = { ...filters, [name]: value };
    onFilterChange(updatedFilters);
  };

  const uniqueValues = (key) => {
    if (!monuments) return [];
    return Array.from(new Set(monuments.map(monument => monument[key]).filter(Boolean)));
  };

  return (
    <Form className="filter-form">
      <div className="filterDivcontainer">
        <Row className="align-items-center flex-nowrap">
          <Col xs={12} sm={2} className="my-1">
            <FilterSelect
              label="Distrito"
              name="district"
              options={uniqueValues("district")}
              selected={filters.district}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} sm={2} className="my-1">
            <FilterSelect
              label="Estilo"
              name="architectureStyle"
              options={uniqueValues("architectureStyle")}
              selected={filters.architectureStyle}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} sm={2} className="my-1">
            <FilterSelect
              label="Século"
              name="century"
              options={uniqueValues("century")}
              selected={filters.century}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} sm={2} className="my-1">
            <FilterSelect
              label="Abertura"
              name="open"
              options={uniqueValues("open")}
              selected={filters.open}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} sm={2} className="my-1">
            <FilterSelect
              label="Preço"
              name="ticketPrice"
              options={uniqueValues("ticketPrice")}
              selected={filters.ticketPrice}
              onChange={handleChange}
            />
          </Col>
          <Col xs={12} sm={2} className="my-1">
            <FilterSelect
              label="Classificação Patrimonial"
              name="heritageClassification"
              options={["Patrimônio Mundial da UNESCO", "Patrimônio Nacional", "Patrimônio Local"]}
              selected={filters.heritageClassification}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FilterPart;
