import React, { useState, useEffect } from 'react';
import './SearchBar.css';

// SearchBar component allows users to search for monuments by name.
const SearchBar = ({ monuments, onMonumentSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const timeoutId = setTimeout(() => {
        const results = monuments.filter(monument =>
          monument.name.toLowerCase().includes(searchTerm.toLowerCase())
        ).slice(0, 6); // Limit to 6 results
        setSearchResults(results);
      }, 300); // debounce delay
      return () => clearTimeout(timeoutId);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, monuments]);

  const handleResultClick = (monument) => {
    setSearchTerm('');
    setSearchResults([]);
    onMonumentSelect(monument);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        aria-label="Search for monuments"
        placeholder="Procura Património..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      {searchResults.length > 0 && (
        <ul className="search-results">
          {searchResults.map((monument) => (
            <li
              key={monument.id}
              onClick={() => handleResultClick(monument)}
              aria-label={`Select ${monument.name}`}
            >
              <img
                src={monument.images[0]}
                alt={monument.name}
                className="search-result-image"
              />
              <span>{monument.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
