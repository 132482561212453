import React from 'react';
import {
  FaLandmark,
  FaUniversity,
  FaRoad,
  FaFlag,
  FaBuilding,
  FaTree,
  FaChurch,
  FaMask,
  FaArchway,
  FaShieldAlt,
  FaGlobe,
  FaIndustry,
  FaHardHat,
  FaMonument,
  FaPaintBrush,
} from 'react-icons/fa'; // Import additional icons
import './IconFilter.css';

const IconFilter = ({ filters, onFilterChange }) => {
  const handleIconClick = (type) => {
    const newFilters = {
      ...filters,
      [type]: !filters[type],
    };
    onFilterChange(newFilters);
  };

  return (
    <div className="icon-filter-container">
      <div
        className={`icon-filter ${filters.museum ? 'active' : ''}`}
        onClick={() => handleIconClick('museum')}
      >
        <FaUniversity size={25} className="iconMark" />
        <span>Museus</span>
      </div>
      <div
        className={`icon-filter ${filters.monument ? 'active' : ''}`}
        onClick={() => handleIconClick('monument')}
      >
        <FaLandmark size={25} className="iconMark" />
        <span>Monumentos</span>
      </div>
      <div
        className={`icon-filter ${filters.n2 ? 'active' : ''}`}
        onClick={() => handleIconClick('n2')}
      >
        <FaRoad size={25} className="iconMark" />
        <span>N2</span>
      </div>
      <div
        className={`icon-filter ${filters.nationalMonument ? 'active' : ''}`}
        onClick={() => handleIconClick('nationalMonument')}
      >
        <FaFlag size={25} className="iconMark" />
        <span>Monumento Nacional</span>
      </div>
      <div
        className={`icon-filter ${filters.municipalMonument ? 'active' : ''}`}
        onClick={() => handleIconClick('municipalMonument')}
      >
        <FaBuilding size={25} className="iconMark" />
        <span>Monumento Municipal</span>
      </div>
      <div
        className={`icon-filter ${filters.naturalSite ? 'active' : ''}`}
        onClick={() => handleIconClick('naturalSite')}
      >
        <FaTree size={25} className="iconMark" />
        <span>Sítios Naturais</span>
      </div>
      <div
        className={`icon-filter ${filters.religiousSite ? 'active' : ''}`}
        onClick={() => handleIconClick('religiousSite')}
      >
        <FaChurch size={25} className="iconMark" />
        <span>Locais Religiosos</span>
      </div>
      <div
        className={`icon-filter ${filters.historicalSite ? 'active' : ''}`}
        onClick={() => handleIconClick('historicalSite')}
      >
        <FaLandmark size={25} className="iconMark" />
        <span>Sítios Históricos</span>
      </div>
      <div
        className={`icon-filter ${filters.culturalLandmark ? 'active' : ''}`}
        onClick={() => handleIconClick('culturalLandmark')}
      >
        <FaMask size={25} className="iconMark" />
        <span>Pontos Culturais</span>
      </div>
      <div
        className={`icon-filter ${filters.architecturalLandmark ? 'active' : ''}`}
        onClick={() => handleIconClick('architecturalLandmark')}
      >
        <FaArchway size={25} className="iconMark" />
        <span>Monumentos Arquitetónicos</span>
      </div>
      <div
        className={`icon-filter ${filters.militarySite ? 'active' : ''}`}
        onClick={() => handleIconClick('militarySite')}
      >
        <FaShieldAlt size={25} className="iconMark" />
        <span>Locais Militares</span>
      </div>
      <div
        className={`icon-filter ${filters.unescoWorldHeritage ? 'active' : ''}`}
        onClick={() => handleIconClick('unescoWorldHeritage')}
      >
        <FaGlobe size={25} className="iconMark" />
        <span>Património Mundial UNESCO</span>
      </div>
      <div
        className={`icon-filter ${filters.industrialHeritage ? 'active' : ''}`}
        onClick={() => handleIconClick('industrialHeritage')}
      >
        <FaIndustry size={25} className="iconMark" />
        <span>Património Industrial</span>
      </div>
      <div
        className={`icon-filter ${filters.archaeologicalSite ? 'active' : ''}`}
        onClick={() => handleIconClick('archaeologicalSite')}
      >
        <FaHardHat size={25} className="iconMark" />
        <span>Sítios Arqueológicos</span>
      </div>
      <div
        className={`icon-filter ${filters.statuesSculptures ? 'active' : ''}`}
        onClick={() => handleIconClick('statuesSculptures')}
      >
        <FaMonument size={25} className="iconMark" />
        <span>Estátuas e Esculturas</span>
      </div>
      <div
        className={`icon-filter ${filters.artMuseums ? 'active' : ''}`}
        onClick={() => handleIconClick('artMuseums')}
      >
        <FaPaintBrush size={25} className="iconMark" />
        <span>Museus de Arte</span>
      </div>
    </div>
  );
};

export default IconFilter;
